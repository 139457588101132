import React from 'react'
import styled from 'styled-components'

const Text = styled.h2`
  font-size: 145px;
  background-color: #ca4246;
  background-image: linear-gradient(
    -90deg,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 0%,
    rgba(${(props) => props.theme.color.gradient.contrast.from}, 1) 15%,
    rgba(${(props) => props.theme.color.gradient.contrast.to}, 1) 50%
  );
  background-size: 400% 300%;
  background-repeat: repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 6s ease infinite;

  @media (max-width: 1199px) {
    font-size: 8.7vw;
  }

  @media screen and (max-width: 575px) {
    text-align: center;
  }

  @keyframes gradient {
    0% {
      background-position: 0 50vw;
    }
    50% {
      background-position: 80vw 50vw;
    }
    100% {
      background-position: 0 50vw;
    }
  }
`

interface TextFullWidthProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextFullWidth
  location?: any
}

const TextFullWidth: React.FC<TextFullWidthProps> = ({ fields }) => (
  <section className="my-5">
    <div className="container">
      <Text>{fields?.text}</Text>
    </div>
  </section>
)

export default TextFullWidth
